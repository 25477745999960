/* Chatbot Container */
.chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  /* Chatbot Toggle Button */
  .chatbot-toggle {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 24px;
  }
  
  /* Chatbot Window */
  .chatbot-window {
    width: 300px;
    height: 400px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 80px;
    right: 20px;
    overflow: hidden;
  }
  
  /* Chatbot Header */
  .chatbot-header {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chatbot-header h3 {
    margin: 0;
  }
  
  .close-button {
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
  }
  
  /* Chat Messages */
  .chatbot-messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
  }
  
  .chat-bubble {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    max-width: 80%;
  }
  
  .user-bubble {
    background-color: #4caf50;
    color: white;
    align-self: flex-end;
  }
  
  .bot-bubble {
    background-color: #f1f1f1;
    color: black;
    align-self: flex-start;
  }
  
  /* Chat Input */
  .chatbot-input {
    display: flex;
    border-top: 1px solid #ddd;
    padding: 10px;
  }
  
  .chatbot-input input {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px;
    margin-right: 5px;
  }
  
  .chatbot-input button {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  