/* frontend/src/components/Navbar.css */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #000000;
  color: #fff;
  position: relative;
  z-index: 2;
}

.navbar-logo-img {
  max-height: 50px;
  width: 50px;
  object-fit: contain;
  margin: 5px 10px;
  border: 2px solid white;
}

@media (max-width: 768px) {
  .navbar-logo-img {
    max-height: 30px; /* Smaller logo for smaller screens */
  }
}


.navbar-logo a {
  color: #fff;
  font-size: 1.5rem;
  text-decoration: none;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 0.3rem;
  z-index: 3;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
}

.navbar-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 15px; /* Adjust gap between links */
}

.navbar-links li {
  position: relative;
  padding: 10px 20px;
  cursor: pointer;
}

.navbar-links li a {
  text-decoration: none;
  color: white; /* Default text color */
  position: relative;
  z-index: 1;
  transition: color 0.3s ease;
}

/* Pseudo-element for the parallelogram effect */
.navbar-links li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d84240; /* Hover background color */
  transform: skew(-20deg) scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
  z-index: 0;
}

/* Hover effect */
.navbar-links li:hover::before {
  transform: skew(-20deg) scaleX(1);
  transform-origin: left;
}

.navbar-links li:hover a {
  color: white; /* Text color on hover */
}


/* Responsive styles */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    background-color: #333;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    padding: 2rem;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
  }

  .navbar-links.open {
    display: flex;
  }

  .navbar-links li {
    text-align: center;
  }
}




