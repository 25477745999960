/* PostDetails.module.css */
.postCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.postTitle {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.postImage img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1.5rem;
}

.postBody {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
  text-align: left;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}


.deleteButton{
  background: red;
  color: white;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  border: none;
}

.deleteButton:hover{
  background-color: rgb(202, 102, 102);
}

.post-details-body{
  text-align: left !important;
}