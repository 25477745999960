/* General Page Styles */
.contact-page {
    font-family: "Fira Sans", sans-serif;
    color: #121212;
    background-color: #f7f7f7; /* Light background */
    padding: 40px 20px;
    display: flex;
    justify-content: center;
  }
  
  .contact-container {
    max-width: 800px; /* Restricts content width */
    width: 100%;
    background-color: white; /* Container background */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 40px; /* Inner padding */
    box-sizing: border-box;
  }
  
  /* Header Section */
  .contact-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .contact-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #121212;
  }
  
  .contact-intro {
    font-size: 1.2rem;
    color: #555;
  }
  
  /* Form Section */
  .contact-form-section {
    margin-top: 20px;
  }
  
  .contact-form-section .ant-form-item {
    margin-bottom: 20px;
  }
  
  .submit-button {
    background-color: red;
    color: white;
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    transition: background-color 0.3s ease;
    width: 100%; /* Make the button span full width on small screens */
  }
  
  .submit-button:hover {
    background-color: darkred;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-title {
      font-size: 2rem;
    }
  
    .contact-intro {
      font-size: 1rem;
    }
  
    .submit-button {
      width: 100%; /* Full-width button on smaller screens */
    }
  }
  

  /* Optional toast styling */
.Toastify__toast {
    font-family: "Fira Sans", sans-serif;
    font-size: 1rem;
    border-radius: 8px;
  }
  
  .Toastify__toast--success {
    background-color: #4caf50; /* Green for success */
    color: white;
  }
  