/* General page styling */
.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    font-family: Arial, sans-serif;
    background-color: white;
  }
  
  /* Post container styling */
  .post {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 800px;
    min-height: 200px;       /* Set a minimum height to maintain consistency */
    width: 100%;
    margin: 1.5rem 0;
    padding: 1.5rem;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Ensure all post-content sections have the same size */
  .post-content {
    flex: 1;
    padding-right: 1rem;
    overflow: hidden;
  }
  
  .post-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .post-body {
    font-size: 1rem;
    color: #333;
    line-height: 1.5;
    margin-bottom: 1rem;
    overflow: hidden;   /* Hide overflowing content */
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;  /* Limit to 3 lines */
    -webkit-box-orient: vertical;
  }
  
  .read-more {
    font-size: 1rem;
    color: #007bff;
    text-decoration: none;
  }
  
  .read-more:hover {
    text-decoration: underline;
  }
  
  /* Image styling */
  .post-image {
    width: 150px;
    height: 100px;
    flex-shrink: 0;
  }
  
  .post-image img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .category{
    background: #578781;
    color: white;
    padding: 5px 5px 5px 5px;
    border-radius: 5px;
    border: none;
  }
  
  .category:hover{
    background-color: rgb(202, 102, 102);
  }


  .homepage .categories-box {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    background-color: #f5f5f5; /* Light background */
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto; /* Center horizontally if needed */
    max-width: 800px; /* Adjust to fit your layout */
    justify-content: center;
  }
  
  .homepage .category-button {
    padding: 8px 16px;
    font-size: 14px;
    color: #333;
    background-color: #e0e0e0;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s;
    cursor: pointer;
  }
  
  .homepage .category-button:hover {
    background-color: #d0d0d0;
  }
  
  .homepage .category-button:active {
    background-color: #c0c0c0;
  }
  