/* Container for the entire form card */
.Auth-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f7f9fc; /* Light background */
    padding: 20px;
  }
  
  /* Card styling for the form */
  .Auth-form {
    background-color: #ffffff;
    padding: 2rem;
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .Auth-form:hover {
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
  }
  
  /* Logo styling */
  .Auth-form-logo img {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
  }
  
  /* Title styling */
  .Auth-form-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  /* Styling for form groups */
  .form-group {
    margin-bottom: 1.5rem;
    text-align: left;
  }
  
  label {
    display: block;
    font-weight: 500;
    color: #555;
    margin-bottom: 0.5rem;
  }
  
  /* Input field styling */
  .form-control {
    padding: 0.75rem;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
    transition: border-color 0.2s ease;
    width: 100%;
  }
  
  .form-control:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0px 0px 4px rgba(0, 123, 255, 0.2);
  }
  
  /* Button styling */
  .btn-primary {
    background-color: #007bff;
    color: #fff;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  /* Spacing between the button and form fields */
  .d-grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  