/* General Page Styles */
.about-page {
    font-family: "Fira Sans", sans-serif;
    color: #121212;
    background-color: white; /* Light background */
    padding: 40px 20px;
    display: flex;
    justify-content: center;
  }
  
  .about-container {
    max-width: 800px; /* Restricts content width */
    width: 100%; /* Ensures full width on small screens */
    background-color: #f9f9f9; /* Container background */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 40px; /* Inner padding */
    box-sizing: border-box;
  }
  
  /* Header Section */
  .about-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .about-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #121212;
  }
  
  .about-intro {
    font-size: 1.2rem;
    color: #555;
  }
  
  /* Section Styles */
  .about-section {
    margin-bottom: 40px;
  }
  
  .section-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #121212;
  }
  
  .section-text {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* Values List */
  .values-list {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .values-list li {
    font-size: 1.1rem;
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
  }
  
  .values-list li::before {
    content: "•"; /* Bullet point */
    position: absolute;
    left: 0;
    color: red; /* Custom bullet color */
    font-size: 1.5rem;
    line-height: 1rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about-title {
      font-size: 2rem;
    }
  
    .section-title {
      font-size: 1.8rem;
    }
  
    .section-text {
      font-size: 1rem;
    }
  
    .values-list li {
      font-size: 1rem;
    }
  }
  